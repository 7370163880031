<template>
    <b-sidebar id="sidebar-right" right shadow bg-variant="white" backdrop>
        <template #default="{ hide }">
            <b-row>
                <b-col md="12" class="text-center">
                    <b-form-file @change="handleImage" v-model="file" placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..." accept="image/*" />
                </b-col>
                <b-col md="12" class="text-center mt-2">
                    <b-col v-if="imageCropper">
                        <cropper ref="cropper" class="cropper" :src="img" @change="change" :stencil-props="{
                            aspectRatio: 1,
                            movable: true,
                            resizable: true
                        }" :auto-zoom="true" :stencil-size="{ width: 200, height: 200 }" image-restriction="stencil" />
                    </b-col>
                </b-col>
                <b-col md="12" class="text-center mt-2" v-if="imageCropper">
                    <img :src="image" alt="Cropped Image" width="70px" height="70px" />
                </b-col>
                <b-col class="text-center">
                    <b-button class="mt-1" @click="hide" v-on:click="getValue()">
                        Upload
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </b-sidebar>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {
    BButton, BRow, BCol, BSidebar, BFormFile
} from 'bootstrap-vue'

export default {
    components: {
        Cropper, BButton, BRow, BCol, BSidebar, BFormFile
    },
    data() {
        return {
            imageCropper: false,
            img: '',
            image: null,
            file: '',
            imageBase64: '',
            finalImageCropped: ''
        }
    },
    props: {
        getProfileImage: Function
    },
    methods: {

        handleImage(e) {
            const file = e.target.files[0];
            //if (file.size < 5000) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                this.img = 'data:image/*;base64,' + base64String;
                console.log(base64String);
                this.imageCropper = true;
            };
            reader.readAsDataURL(file);

            // } else {
            //     this.file = '';

            // }
        },
        change({ coordinates, canvas }) {
            //console.log(coordinates, canvas);
            this.image = canvas.toDataURL();
            this.finalImageCropped = canvas.toDataURL()
                .replace('data:', '')
                .replace(/^.+,/, '');
        },
        getValue() {
            console.log(this.image);
            this.getProfileImage(this.finalImageCropped);
            this.file = '';
            this.imageCropper = false;
        }
    },
};
</script>
<style>
.cropper {
    height: 250px;
    width: 250px;
    background: #DDD;
}
</style>
