<template>
    <b-card title="Items">
        <b-form>
            <b-row>
                <b-col md="2">
                    <b-form-group label="Item Category">
                        <v-select v-model="category" label="name" placeholder="Select Category"
                            :options="categoryOption"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Item Name">
                        <b-form-input v-model="itemName" placeholder="Please Enter Item Name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Short Code">
                        <b-form-input v-model="shortCode" placeholder="Please Enter Short Code"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Pieces">
                        <!-- <b-form-input v-model="pieceCount" placeholder="Please Enter Piece Count"></b-form-input> -->
                        <b-form-tags v-model="pices" input-id="tags-remove-on-delete"
                            :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }" separator=" "
                            placeholder="Enter new tags separated by space" remove-on-delete no-add-on-enter
                            class="mb-2" />
                    </b-form-group>
                </b-col>
                <b-col md="2" class="text-center">
                    <!-- <b-form-group label="Item Photo"> -->
                    <!-- <b-form-file @change="handleImage" v-model="file" placeholder="Choose a file or drop it here..."
                         drop-placeholder="Drop file here..." accept="image/*" /> -->
                    <img :src="itemImage" width="70px" alt="Item Photo" />
                    <b-button v-b-toggle.sidebar-right style="font-size:12px" variant="success">Upload Item
                        Image</b-button>
                    <!-- </b-form-group> -->
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdateitems">{{ addUpdateButton }}
                    </b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">
                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'itemPic'" class="text-nowrap">

                            <img v-if="props.row.itemPic != ''" :src=convertImage(props.row.itemPic) height="70px" />
                        </span>

                        <span v-else-if="props.column.field === 'pices'" class="text-nowrap">

                            <span v-for="pieceName in props.row.pices" :key="pieceName">
                                {{pieceName+', '}}
                            </span>
                        </span>
                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-button
                                    @click="editButton(props.row.id, props.row.category.id, props.row.category.name, props.row.name, props.row.sortCode, props.row.pieceCount, props.row.pices, props.row.itemPic)"
                                    variant="flat-primary"><feather-icon icon="EditIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                                <b-button @click="deleteItemName(props.row.id)" variant="flat-primary"><feather-icon
                                        icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
                <image-cropper :getProfileImage="getProfileImage"></image-cropper>
            </div>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, VBToggle, BBadge, BFormFile, BFormTags, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import vSelect from 'vue-select'
import ImageCropper from '../my-account/ImageCropper.vue'
import util from '@/store/utils'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown, BFormFile,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, vSelect, VBToggle, ImageCropper, BFormTags
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            addUpdateButton: 'Add',
            categoryOption: [],
            category: null,
            itemName: '',
            shortCode: '',
            pieceCount: '',
            pices: [],
            id: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Item Category',
                    field: 'category.name',
                },
                {
                    label: 'Item Photo',
                    field: 'itemPic',
                },
                {
                    label: 'Item Name',
                    field: 'name',
                },
                {
                    label: 'Short Code',
                    field: 'sortCode',
                },
                {
                    label: 'Piece Count',
                    field: 'pieceCount',
                },
                {
                    label: 'Pieces',
                    field: 'pices',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            file: '',
            imageBase64: '',
            itemImage: null,
        }
    },
    methods: {
        getProfileImage(profileImage) {
            this.itemImage = util.convertImage(profileImage);
            this.imageBase64 = profileImage;
        },
        // handleImage(e) {
        //     const file = e.target.files[0];
        //     if (file.size < 5000) {
        //         const reader = new FileReader();
        //         reader.onloadend = () => {
        //             const base64String = reader.result
        //                 .replace('data:', '')
        //                 .replace(/^.+,/, '');
        //             this.imageBase64 = base64String;
        //             console.log(base64String);
        //         };
        //         reader.readAsDataURL(file);
        //     } else {
        //         this.file='';

        //     }
        // },

        convertImage(itemPic) {

            return 'data:image/*;base64,' + itemPic
        },

        getItemCategory() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/itemCategories', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.categoryOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getItemNames() {
            this.id = 0;
            this.category = null,
                this.itemName = '';
            this.shortCode = '';
            this.pieceCount = '';
            this.pices = [];
            this.file = '';
            this.addUpdateButton = "Add";
            this.base64String = '';
            this.itemImage = null;
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/items', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateitems() {
            let self = this;
            if(self.pices.length==0){
                self.pices.push(self.itemName);
            }
            if (!(self.itemName == '')) {
                if (this.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    if (self.category == null) {
                        self.category = null
                    } else {
                        self.category = { 'id': self.category.id, 'name': self.category.name };
                    }
                    var data = JSON.stringify({
                        'category' : self.category,
                        "name" : self.itemName,
                        'sortCode' : self.shortCode,
                        'pieceCount' : self.pices.length,
                        'pices' : self.pices,
                        'itemPic' : self.imageBase64
                    });
                    axios(api.getApi('Post', '/masters/items', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Item Name Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getItemNames();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({ 
                        'category': self.category, 
                        "name": self.itemName, 
                        'sortCode': self.shortCode, 
                        'pieceCount': self.pices.length, 
                        'pices' : self.pices,
                        'itemPic': self.imageBase64 
                    });
                    axios(api.getApi('put', '/masters/items/' + this.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getItemNames();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Item Name Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Item Name.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        deleteItemName(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/items/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getItemNames();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        editButton(id, categoryId, categoryName, itemName, shortCode, pieceCount,pices, itemPic) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.category = { 'id': categoryId, 'name': categoryName };
            this.itemName = itemName;
            this.shortCode = shortCode;
            this.pieceCount = pieceCount;
            this.pices=pices;
            this.file = itemPic;
            this.imageBase64 = itemPic;

        }
    },
    mounted() {

    },
    computed: {

    },
    created() {
        let self = this;
        self.getItemNames();
        self.getItemCategory();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
